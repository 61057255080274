@import "../../node_modules/rsuite/dist/rsuite.css";

body{

}

#root{
    /*padding: 20px;*/
    /*background: aliceblue;*/
}

.sigin_div{
    width: 100px;
    height: 100px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}